import React from 'react';
import './App.css';
import logo from './Klops_LOGO.png';
import user from './user.png';
import cart from './cart.png';
import back from './back.png';
import {Button} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import featured from './FeaturedProd.PNG';
import mobile_drop from './dropdown.png';
import camera1 from './kaamera1.PNG';
import camera2 from './kaamera2.PNG';
import camera3 from './kaamera3.PNG';
import camera4 from './kaamera4.PNG';
import backBtn from './backBtn.png';
import nextBtn from './nextBtn.png';
import email from './email.png';
import location from './location.png';
import twitter from './twitterlogo.png';
import facebook from './facebooklogo.png';
import instagram from './instagramlogo.png';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

class DirectoryHandler extends React.Component {
    render() {
        return (
            <Router>
                <Route path="/" exact component={App}/>
                <Route path="/Details" component={Details}/>
            </Router>
        );
    }

}

class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isHidden: true}
    }

    handleClick() {
        this.setState({isHidden: !this.state.isHidden})
    }

    render() {
        const {image, ProductPrice, imgAlt, imgHeight} = this.props.location.state;
        return (
            <div className={'flexFooterFix'}>
                <div className={"header"}>
                    <div className={'flexFix'}>
                        <Link to={'/'} ><img src={backBtn} alt={'back'} height={25} /></Link>
                    </div>
                    <div className={'middleLogoText'}>
                        <img src={logo} alt="Klops Logo" className={'klopsLogo'}/>
                        <p className={'logoTextThing'} style={{color: "white", marginTop: 10, letterSpacing: 4}}>SHOP
                            ALL  &nbsp;  /  &nbsp; RENTING
                            CONDITIONS &nbsp;  /  &nbsp; INFO</p>
                        <div className={'mob_icons'}>
                            <img src={user} onClick={() => this.handleClick()} alt={'userIcon'} style={{paddingRight: 10, marginRight: 50}} height={24}/>
                            <img src={cart} alt={'shoppingCart'} style={{paddingRight: 10, marginLeft: 50}}
                                 height={24}/>
                        </div>
                    </div>
                    <div className={'LangSetting_Account'}>
                        <Button.Group className={"language"}>
                            <Button inverted className={"langStyle"}
                                    onClick={() => alert('Clicked EST')}>EST</Button>&nbsp;&nbsp;&nbsp;
                            <Button inverted className={"langStyle"}
                                    onClick={() => alert('Clicked RUS')}>RUS</Button>&nbsp;&nbsp;&nbsp;
                            <Button inverted className={"langStyle"} onClick={() => alert('Clicked ENG')}>ENG</Button>
                        </Button.Group>
                        <div className={'HeaderIcons'}>
                            <div className={'AccountLOGIN'} onClick={() => this.handleClick()}>
                                <img src={user} alt={'userIcon'} style={{paddingRight: 10}} height={24}/>
                                <p>LOG IN</p>
                            </div>
                            <div className={'Cart'}>
                                <img src={cart} alt={'shoppingCart'} style={{paddingRight: 10}} height={24}/>
                                <p>CART</p>
                            </div>
                        </div>
                    </div>
                </div>

                {!(this.state.isHidden) &&
                <PopUpLogin handler={() => this.handleClick()}/>
                }

                <div className={'mobile_detail'}>
                    <div className={'mob_product'}>
                        <p style={{fontWeight: "bold", fontSize: 24}}>SOME BIG CANNON</p>
                        <img src={image} alt={imgAlt} height={imgHeight}/>
                        <p style={{marginTop: 15, fontSize: 18, fontWeight: "lighter"}}>{ProductPrice}</p>
                        <button className={'BORROWBUTTON'} onClick={() => alert('SUCCESS')}>BORROW NOW</button>
                        <div className={'mob_detail_banner'} style={{marginTop:15}}/>
                    </div>
                    <div className={'mob_detail_desc'} style={{marginTop: 15}}>

                        <p style={{padding: 10}}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                    </div>


                </div>


                    <div className={"Product"}>
                        <div className={'ProductImageAndPrice'}>
                            <img src={image} alt={imgAlt} height={imgHeight * 2}/>
                            <p style={{fontSize: 16, marginTop: 30}}>{ProductPrice}</p>
                        </div>
                        <div>
                            <p className={'itemName'}>Some text about the product</p>
                            <p className={'normalText'}>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                            </p>
                            <p className={'itemName'}>Specifications</p>
                            <p className={'normalText'}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
                            <button className={'BORROWBUTTON'} onClick={() => alert('SUCCESS')}>BORROW NOW</button>
                        </div>

                    </div>


                <div className={'footer'} style={{position: "relative", marginTop: 10}}>
                    <div className={'locationEmail'}>
                        <div className={'location'}>
                            <img style={{marginLeft: 15}} src={location} alt={'location icon'} height={30}/>
                            <p style={{marginLeft: 15}}>Handelmort Mansion Gielinor</p>
                        </div>
                        <div className={'email'}>
                            <img style={{marginLeft: 15}} src={email} alt={'email icon'} height={25}/>
                            <p style={{marginLeft: 15}}>randomRandom@Steam.com</p>
                        </div>
                    </div>
                    <div className={'SocialMedia'}>
                        <a href="https://www.facebook.com">
                            <img src={facebook} alt={"facebook"} height={24} style={{marginBottom: 3}}/>
                        </a>
                        <a href="https://www.instagram.com">
                            <img src={instagram} alt={"instagram"} height={35}/>
                        </a>
                        <a href="https://www.twitter.com">
                            <img src={twitter} alt={"twitter"} height={30}/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}


class PopUpLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {textChange: 'Log In'}
    }

    changeToReg() {
        this.setState({textChange: 'Register'})
    }

    changeToLog() {
        this.setState({textChange: 'Log In'})
    }

    render() {
        const {handler} = this.props;
        return (
            <div className={'blur'}>
                <div className={'popupContainer'}>
                    <img src={back} alt={'backBTN'} height={30}
                         style={{marginTop: 15, marginLeft: 10, cursor: "pointer"}} onClick={handler}/>
                    <div className={'popupHeader'}>
                        <p>{this.state.textChange}</p>
                    </div>
                    <div className={'popupBody'}>
                        <input type='text' placeholder={"Email"}/>
                        <input type='text' placeholder={"UserName"}/>
                        <input type='text' placeholder={"Password"}/>
                    </div>
                    <div className={'loginBtn'}>
                        <Button className={"langStyle"} onClick={() => this.changeToLog()}>Log In</Button>
                        <Button className={"langStyle"} style={{marginLeft: 15}}
                                onClick={() => this.changeToReg()}>Register</Button>
                    </div>
                </div>
            </div>
        );
    }
}

class Camera extends React.Component {

    render() {
        const handleOnDragStart = e => e.preventDefault();
        const {img, alt, height, price} = this.props;
        return (
            <div className={"CarouselItem"}>
                <img src={img} onDragStart={handleOnDragStart} alt={alt} height={height}/>
                <p style={{marginTop: 15, marginBottom: 25}}>{price}</p>
                <Link to={{
                    pathname: "/Details",
                    state: {image: img, ProductPrice: price, imgAlt: alt, imgHeight: height}
                }}><Button style={{marginBottom: 15, marginTop: 15}}>BORROW NOW</Button></Link>
            </div>
        );
    }

}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {isHidden: true}
    }

    handleClick() {
        this.setState({isHidden: !this.state.isHidden})
    }

    render() {

        const responsive = {
            1024: {
                items: 4
            },
            768: {
                items: 3
            }
        };
        return (
            <div className="main">
                <div className={"header"}>
                    <div className={'flexFix'}>
                        <img src={mobile_drop} alt={'mobile dropdown'} className={'mobile_dropdown'}/>
                    </div>
                    <div className={'middleLogoText'}>
                        <img src={logo} alt="Klops Logo" className={'klopsLogo'}/>
                        <p className={'logoTextThing'} style={{color: "white", marginTop: 10, letterSpacing: 4}}>SHOP
                            ALL  &nbsp;  /  &nbsp; RENTING
                            CONDITIONS &nbsp;  /  &nbsp; INFO</p>
                        <div className={'mob_icons'}>
                            <img src={user} onClick={() => this.handleClick()} alt={'userIcon'} style={{paddingRight: 10, marginRight: 50}} height={24}/>
                            <img src={cart} alt={'shoppingCart'} style={{paddingRight: 10, marginLeft: 50}}
                                 height={24}/>
                        </div>
                    </div>
                    <div className={'LangSetting_Account'}>
                        <Button.Group className={"language"}>
                            <Button inverted className={"langStyle"}
                                    onClick={() => alert('Clicked EST')}>EST</Button>&nbsp;&nbsp;&nbsp;
                            <Button inverted className={"langStyle"}
                                    onClick={() => alert('Clicked RUS')}>RUS</Button>&nbsp;&nbsp;&nbsp;
                            <Button inverted className={"langStyle"} onClick={() => alert('Clicked ENG')}>ENG</Button>
                            <a href="index_old1.html"><Button inverted className={"langStyle"}>Vana leht</Button></a> &nbsp;&nbsp;&nbsp;
                        </Button.Group>
                        <div className={'HeaderIcons'}>
                            <div className={'AccountLOGIN'} onClick={() => this.handleClick()}>
                                <img src={user} alt={'userIcon'} style={{paddingRight: 10}} height={24}/>
                                <p>LOG IN</p>
                            </div>
                            <div className={'Cart'}>
                                <img src={cart} alt={'shoppingCart'} style={{paddingRight: 10}} height={24}/>
                                <p>CART</p>
                            </div>
                        </div>
                    </div>
                </div>

                {!(this.state.isHidden) &&
                <PopUpLogin handler={() => this.handleClick()}/>
                }

                <div className={'mob_body'}>
                    <p style={{letterSpacing: 3, marginTop: 15, textAlign: 'center'}}>PRODUCTS</p>
                    <Camera img={camera1} alt={"Camera1"} height={150} price={'234.22'}/>
                    <Camera img={camera2} alt={"Camera2"} height={150} price={'234.22'}/>
                    <Camera img={camera3} alt={"Camera3"} height={150} price={'234.22'}/>
                    <Camera img={camera4} alt={"Camera4"} height={150} price={'234.22'}/>
                </div>

                <div className={'Body'}>
                    <div className={'FeaturedProduct'}>
                        <img src={featured} alt={'featuredProdcut'} height={500} style={{marginLeft: 100}}
                             className={'featuredImage'}/>
                        <div className={'wholeProductContainer'}>
                            <div className={'WhiteLineWithText'}>
                                <div style={{
                                    height: 150,
                                    width: 2,
                                    background: "white",
                                    marginLeft: 80,
                                    marginRight: 20
                                }}/>
                                <div className={'FeaturedProdDescription'}>
                                    <p style={{fontWeight: "bold", color: 'white', letterSpacing: 3, fontSize: 18}}>NEW
                                        PRODUCT</p>
                                    <p style={{
                                        fontWeight: "light",
                                        color: 'white',
                                        letterSpacing: 4,
                                        fontSize: 24
                                    }}>TAMRON
                                        CANON SP AF 0-200mm</p>
                                    <p style={{
                                        fontWeight: "lighter",
                                        color: 'white',
                                        letterSpacing: 3,
                                        fontSize: 16
                                    }}>Engineered in Germany, made for precision.</p>
                                </div>
                            </div>
                            <Link to={{
                                pathname: "/Details",
                                state: {image: featured, ProductPrice: '999.99€', imgAlt: 'featured', imgHeight: 300}
                            }}><Button style={{marginTop: 30}}>BORROW NOW !</Button></Link>
                        </div>
                    </div>


                    <div className={"PopularItems"}>
                        <p style={{fontSize: 18, marginTop: 50, marginBottom: 50}}
                           className={'PopularItemsHeader'}>POPULAR ITEMS</p>
                        <div className={'products'}>
                            <img src={backBtn} onClick={() => this.Carousel._slidePrev()}
                                 style={{marginRight: 30, cursor: "pointer"}} alt={'backBtn'} height={35}/>
                            <AliceCarousel mouseDragEnabled responsive={responsive} dotsDisabled buttonsDisabled
                                           ref={el => this.Carousel = el}>
                                <Camera img={camera1} alt={"Camera1"} height={150} price={'195.98 €'}/>
                                <Camera img={camera2} alt={"Camera2"} height={150} price={'195.98 €'}/>
                                <Camera img={camera3} alt={"Camera3"} height={150} price={'195.98 €'}/>
                                <Camera img={camera4} alt={"Camera4"} height={150} price={'195.98 €'}/>
                            </AliceCarousel>
                            <img src={nextBtn} onClick={() => this.Carousel._slideNext()}
                                 style={{marginLeft: 30, cursor: "pointer"}} alt={'nextBtn'} height={35}/>
                        </div>
                    </div>

                </div>

                <div className={'footer'}>
                    <div className={'locationEmail'}>
                        <div className={'location'}>
                            <img style={{marginLeft: 15}} src={location} alt={'location icon'} height={30}/>
                            <p style={{marginLeft: 15}}>Handelmort Mansion Gielinor 92</p>
                        </div>
                        <div className={'email'}>
                            <img style={{marginLeft: 15}} src={email} alt={'email icon'} height={25}/>
                            <p style={{marginLeft: 15}}>GabeNewell@Steam.com</p>
                        </div>
                    </div>
                    <div className={'SocialMedia'}>
                        <a href="https://www.facebook.com">
                            <img src={facebook} alt={"facebook"} height={24} style={{marginBottom: 3}}/>
                        </a>
                        <a href="https://www.instagram.com">
                            <img src={instagram} alt={"instagram"} height={35}/>
                        </a>
                        <a href="https://www.twitter.com">
                            <img src={twitter} alt={"twitter"} height={30}/>
                        </a>
                    </div>
                </div>

            </div>

        );
    }
}


export default DirectoryHandler;
